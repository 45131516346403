import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Alert, Spinner,Button,ProgressBar } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers'
import TokenSaleAndStakingABI from '../abis/TokenSaleAndStakingABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import NodesABI from '../abis/NodesABI.json'; // ABI of your contract

import { useContract } from "../hooks/useContract";

import logoToken from '../logoToken.png'; 
import { useNavigate } from 'react-router-dom';

const BuyToken2 = ({ provider, account }) => {
    const { collections, ownedNFTs, fetchOwnedNFTs, totalRewards } =
    useContract(provider);
    const [ethBalance, setEthBalance] = useState('0');
    const [usdtBalance, setUsdtBalance] = useState('0');
    const [tokenBalance, setTokenBalance] = useState('0');
    const [presales, setPresales] = useState([]);
    const [selectedPresaleIndex, setSelectedPresaleIndex] = useState(0);
    const [amountToBuy, setAmountToBuy] = useState('');
    const [amountToRecive, setAmountToRecive] = useState('0');
    const [activePresale, setActivePresale] = useState(null);
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [stakes, setStakes] = useState([]);
    const [boughtAmount, setBoughtAmount] = useState(0);
    const [numOwnedNFTs, setNumOwnedNFTs] = useState(0);

    const navigate = useNavigate();

    const presalePrice = 0.00005;
    const maxBuy = 1000;

    const getProgress = (timestamp) => {
        const now = Math.floor(Date.now() / 1000);
        const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
        const timePassed = now - timestamp;
        const progress = (timePassed / thirtyDaysInSeconds) * 100;
        
        return Math.min(progress, 100); // Ensure progress does not exceed 100%
    };


    async function getBalance() {
   
    
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
        const USDTBalance = await USDTContract.balanceOf(account);
        const USDTDecimals = await USDTContract.decimals();
        setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));

        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));

        const balance = await ethersProvider.getBalance(account);
        setEthBalance(formatUnits(balance, 18));
     
       
        const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
        
        
        const nftsForUser = await contract.getAllNFTsForUser(account);
     
  
        setNumOwnedNFTs(nftsForUser.length);
      }

    // Fetch presale details from the contract
    useEffect(() => {
        const fetchPresales = async () => {
            if (!provider || !account) return;
            const ethersProvider = new BrowserProvider(provider);
            const contract = new Contract(SETTINGS.mainContract2, TokenSaleAndStakingABI, ethersProvider);
         
            const pArr = await contract.getAllPresales();
          
            
            let data = [];
            for(let i=0;i<pArr.length;i++){
                const leftAmount = parseFloat(formatUnits(pArr[i].tokenAmount.toString(), 18).toString()) - parseFloat(formatUnits(pArr[i].soldAmount.toString(), 18).toString());
                data.push({
                    tokenAmount:pArr[i].tokenAmount.toString(),
                    tokenAmountDec:formatUnits(pArr[i].tokenAmount.toString(), 18).toString(),
                    price:pArr[i].price.toString(),
                    priceDec:formatUnits(pArr[i].price.toString(), 18).toString(),
                    soldAmount:pArr[i].soldAmount.toString(),
                    leftAmount:leftAmount,
                    isActive:pArr[i].isActive,
                })
            }
           
            setPresales(data);
            const active = data.find((presale) => presale.isActive);
            setActivePresale(active);
            setSelectedPresaleIndex(data.indexOf(active));
        };
        fetchPresales();
        getBalance();
        fetchStakes();
    }, [provider, account]);

    const _setAmountToBuy = async (amount) => {
        setAmountToBuy(amount);
        const price = parseFloat(activePresale.priceDec);
        const tokensToRecive = parseFloat(amount) / price;
        setAmountToRecive(tokensToRecive.toFixed(4));

    };

    const fetchStakes = async () => {
        setLoading(true);
        try {
            const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.mainContract2, TokenSaleAndStakingABI, signer);
            const stakesDataUser = await contract.getUserStakes(account);
            
            const serialized = JSON.stringify(stakesDataUser, (key, value) =>
            typeof value === 'bigint' ? value.toString() : value
            );
            const stakesData = JSON.parse(serialized);
       
            let stakesArr = []
            let boughtAm = 0;
            for(let i=0;i<stakesData.length;i++){
                const stake = stakesData[i];
                boughtAm += parseFloat(formatUnits(stake["0"].toString(), 18));
                stakesArr.push(
                    {
                        amount: formatUnits(stake["0"].toString(), 18),
                        timestamp: stake["1"],
                        claimed: stake["2"].claimed,
                        i
                    }
                );
            }
            setStakes(stakesArr);
            setBoughtAmount(boughtAm * presalePrice);
           
        } catch (error) {
            console.error("Failed to fetch stakes", error);
        } finally {
            setLoading(false);
        }
    };
 const handleBuyToken = async () => {
       if(maxBuy < (boughtAmount + parseFloat(amountToBuy))){
        alert("Max buy per wallet is 1000 USDT.");
        return;
       }
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            setTxMessage("Approving USDT transfer...");
            
            const usdtContract = new Contract(
                SETTINGS.usdtAddress,
                ERC20_ABI,
                signer
            );

            // Convert the amount to buy to wei (USDT has 6 decimals)
            const amountToBuyWei = parseUnits(amountToBuy.toString(), 6);

            // Approve the token sale contract to spend USDT
            const approveTx = await usdtContract.approve(
                SETTINGS.mainContract2,
                amountToBuyWei
            );
            await approveTx.wait();

            setTxMessage("Purchasing tokens...");

            // Now call the buyToken function
            const tokenSaleContract = new Contract(
                SETTINGS.mainContract2,
                TokenSaleAndStakingABI,
                signer
            );

            const buyTx = await tokenSaleContract.buyToken(
                selectedPresaleIndex,
                amountToBuyWei
            );
            await buyTx.wait();

            setTxMessage("Tokens purchased successfully!");
            console.log("Tokens purchased with USDT!");

            // Update UI after purchase
            // Fetch updated presales information, etc.

        } catch (error) {
            console.error("Transaction failed!", error);
            setTxMessage("Transaction failed. Please try again.");
        } finally {
            setLoading(false);
            // Reset or update the message after a delay
            setTimeout(() => setTxMessage(""), 5000);
            fetchStakes();
        }
    };

    if (loading) {
        return (
            <div className="loaderScreen text-center">
              <br />
            <br />
            <br />
            <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    if (presales.length === 0) {
        return (
            <div className="loaderScreen text-center">
            <br />
            <br />
            <br />
            <br />
              <Spinner animation="border" role="status" className='loaderBig' />
              <p className='loaderMsg'>{txMessage}</p>
          </div>
        );
    }

    if (!activePresale) {
        return <Alert variant="warning">There are currently no active presales. Please check back later.</Alert>;
    }

    return (
        <div>
           <Row>
           <Col sm={12} md={8} lg={6}>
            <h4>Buy ${SETTINGS.tokenSymbol} Presale #2</h4>
            <div className='pokemon-card'>
            <div className="buy-token-header text-center">
            
            </div>
            <div className="pokemon-card token-exchange-info">
                <div> <img src={logoToken}  style={{width:"35px", marginRight:"5px",  marginleft:"30px", marginTop:"-5px"}} />
                    1 ${SETTINGS.tokenSymbol} = 
                     <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"  style={{width:"35px", marginRight:"5px",  marginLeft:"10px", marginTop:"-5px"}} />
                    
                    {activePresale.priceDec} USDT</div>
                    <br />
            </div>
            
        
            <div className="">
        
                <div className="input-group text-center">
                <h5>Enter amount in USDT</h5>
                    <input
                        type="text"
                        value={amountToBuy}
                        onChange={(e) => _setAmountToBuy(e.target.value)}
                        placeholder="Amount in USDT"
                        disabled
                    />
                    
           
                    
                    
                </div>
                <Button className="buton" disabled>Not available</Button>
            
                <h5><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"  style={{width:"18px", marginRight:"10px",  marginLeft:"10px", marginTop:"-2px"}} /> 
                    <small>Balance: {usdtBalance} USDT</small></h5>
                    <div className="gas-info">
  Max USDT to Spend: {maxBuy} USDT<br />
  Amount Spent: {boughtAmount} USDT<br />
  Remaining Balance: {maxBuy - boughtAmount} USDT
</div>
<div className="gas-info">
  ${SETTINGS.tokenSymbol} tokens from Presale #2 will begin unlocking on 30.03.2025. 
  On this date, 20% of the tokens will be unlocked, with the remaining 80% unlocking daily over the next 365 days.
</div>

            </div>
          
        </div>
        </Col>
            <Col sm={12} md={8} lg={6}>
        <div className="staking-container">
                   
                        {stakes.length > 0 ? (<h4>Your vestings</h4>):(<></>)}
                        {stakes.map((stake, index) => (
                            <Card key={index} className="mb-3 pokemon-card" >
                            <Card.Body>
                                <Row>
                                <Col xs={8} md={8}>
                                <h6 style={{marginTop:"15px"}}>
                                <img src={logoToken} className='tokenIconColor'   style={{width:"25px", marginRight:"10px", marginLeft:"10px", marginTop:"-5px"}} />
                  
                                    Vested: {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.amount)} 
                                     <small> {SETTINGS.tokenSymbol}</small>
                                     </h6>
                                     <small>
                                        
                                     </small>
                                </Col>
                                <Col sm={4} md={4}>
                                <ProgressBar
                            variant='warning'
                            striped
                            animated
                            now="100"
                            style={{ height: '10px', fontSize: '1em', marginTop:"18px", marginRight:"20px" }}
                        />
                                </Col>
                                </Row>
                            </Card.Body>
                            </Card>
                        ))}
                    </div>
       
        <div style={{ padding: '0 10px' }}>
      
            <h4>Presales</h4>
            {presales.map((presale, index) => (
                <Card key={index} className="mb-3 pokemon-card" >
                    <Card.Body>
                        <h3>Presale {index + 2} </h3>
                        
                            <h6 style={{ fontSize: '1em' }}>Price: {formatUnits(presale.price.toString(), 'ether')} USDT</h6>
                            
                            <h6 style={{ fontSize: '0.9em', display:"none" }}>Available in presale: {presale.leftAmount.toLocaleString(navigator.language, { minimumFractionDigits: 2 })} {SETTINGS.tokenSymbol}</h6>
                            <h6 style={{ fontSize: '0.9em' }}>Available in presale: 0 {SETTINGS.tokenSymbol}</h6>
                            <h6 style={{ fontSize: '0.9em' }}><small>FINISHED</small></h6>
                          
                            
                       {/* now={(presale.leftAmount / presale.tokenAmount) * 100} */}

                            <ProgressBar
                          
                            striped
                            now="100"
                            variant="success"
                            style={{ height: '10px', fontSize: '1em' }}
                        />
                        
                    </Card.Body>
                </Card>
            ))}
        
</div>
        </Col>
        </Row>
       
           
    
        </div>
    );
};

export default BuyToken2;
